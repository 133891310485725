import Meet from "./Meeting"

const Layout = () => {
  return (
		<>
      {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <img src="logo-mini.png" height={"70px"}/>
        <img style={{ opacity: "0"}} src="logo-mini.png" height={"80px"}/>
      </div> */}
      <div style={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Meet />
      </div>
    </>
  )
}

export default Layout