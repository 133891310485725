import { useState, useEffect, useRef } from "react";
import { DyteMeeting, provideDyteDesignSystem } from "@dytesdk/react-ui-kit";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import { createMeeting, joinMeeting } from "../utils/api";
import DyteCustomMeeting from "./DyteCustomMeeting";
import DyteVideoBackgroundTransformer from '@dytesdk/video-background-transformer';

const Meet = () => {
	const meetingEl = useRef(null);
	// const messages = useDyteSelector((m) => m.chat.messages);
	// console.log('messages', messages)

	const [meeting, initMeeting] = useDyteClient();
	const [userToken, setUserToken] = useState();
	const [meetingId, setMeetingId] = useState('');

	const createMeetingId = async () => {
		const newMeetingId = await createMeeting();
		setMeetingId(newMeetingId);
	};

	const renderLoadingLogo = () => (
    <div className="loaderClass">
      <img src="logo-mini.png" />
    </div>
  )

	useEffect(() => {
		const id = window.location.pathname.split("/")[1];
		// if (!id) {
		// 	createMeetingId();
		// }
		if (id) return setMeetingId(id);
		window.location.href = 'https://poc.baodaotalk.com'
	}, []);

	const isLocal = process.env.REACT_APP_NODE_ENV === 'local'

	const joinMeetingId = async () => {
		if (meetingId) {
			const authToken = await joinMeeting(meetingId);
			await initMeeting({
				authToken,
        defaults: {
          audio: true,
          video: false,
        },
        modules: {
          plugin: true,
          devTools: isLocal && {
            logs: false,
            plugins: [
              {
                name: 'BaoDaoTalk-note-editor',
                port: 5000,
                id: '1ca8c848-6068-4a2a-9d9c-b35579d421bb',
              },
            ]
          }
        },
			}).then(async (meeting) => {
        const videoBackgroundTransformer =
        await DyteVideoBackgroundTransformer.init();
        meeting.self.addVideoMiddleware(
          await videoBackgroundTransformer.createBackgroundBlurVideoMiddleware(10)
        );
      });;
			setUserToken(authToken);
		}
	};

	useEffect(() => {
		if (meetingId && !userToken) joinMeetingId();
	}, [meetingId]);

	useEffect(() => {
		if (userToken) {
			provideDyteDesignSystem(meetingEl.current, {
				// theme: "dark",
			});
		}
	}, [userToken]);

	return (
		<>
			{userToken && meetingId ? (
				// <DyteProvider value={meeting}>
					// <DyteCustomMeeting />
					<DyteMeeting mode="fill" meeting={meeting} ref={meetingEl} />
				// </DyteProvider>
			) : (
				renderLoadingLogo()
			)}
		</>
	);
};

export default Meet;
