import axios from 'axios';
const API_HASH = btoa(
  `${process.env.REACT_APP_DYTE_ORG_ID}:${process.env.REACT_APP_DYTE_API_KEY}`
);
const baseURL = 'https://api.cluster.dyte.in/v2';

const DyteAPI = axios.create({
  baseURL,
  headers: {
    Authorization: `Basic ${API_HASH}`,
  },
});

const createMeeting = async () => {
  const response = await DyteAPI.post('/meetings', {
    title: '20230824 meeting',
  });
  // const resp = await fetch(`${baseURL}/meetings`, {
  //     Authorization: `Basic ${API_HASH}`,
  //     method: "POST",
  //     body: JSON.stringify({ title: "New Code pair" }),
  //     headers: { "Content-Type": "application/json", Authorization: `Basic ${API_HASH}` }
  // })
  const data = await response.data
  console.log(data)
  return data.data.id;
}

const joinMeeting = async (id) => {
  // const { name, picture, preset_name } = req.body
  // 未來是我們的 user id
  const name = 'new user'
  const client_specific_id = `baodao-talk::${name.replaceAll(' ', '-')}-${Math.random().toString(36).substring(2, 7)}`;

  const response = await DyteAPI.post(`/meetings/${id}/participants`, {
    name, preset_name: "test",
    client_specific_id
  });
  console.log(response.data)
  return response.data.data.token;
}

export { createMeeting, joinMeeting }